<template>
    <div id="page-number-porting-number-port-details" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Number Ports</router-link>
                </div>

                <div class="col-6 col-tab-12">
                    <app-table-details
                        title="Porting details"

                        :rows="rows"
                        :data="data"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

const PRODUCT_TYPES = {
    prepaymobile: 'Prepay',
    postpaymobile: 'Postpay',
}

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appTableDetails,
    },

    data() {
        return {
            port: null,

            loading: false,

            referrer: null,
            on_sidebar: false,
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            this.getPortDetails()
        },

        getPortDetails() {
            this.loading = true

            const params = {
                UUID: this.port_uuid,
                SPID: this.current_spid,
            }

            this.$store.dispatch('api_lmnpproto/GetServiceProviderPortByUUID', params)
                .then(response => {
                    this.port = response

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'number-porting-number-ports'
                ? this.referrer
                : { name: 'number-porting-number-ports' }
        },

        port_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        rows() {
            return [
                { key: 'uuid',                    title: 'ID'                                     },
                { key: 'started_at',              title: 'Started at'                             },
                { key: 'status',                  title: 'Status',                 type: 'status' },
                { key: 'type',                    title: 'Type'                                   },
                { key: 'customer_contact_name',   title: 'Customer contact name'                  },
                { key: 'customer_contact_number', title: 'Customer contact number'                },
            ]
        },

        data() {
            let data = {
                uuid: '?',

                started_at: '?',

                status: {
                    status: 'info',
                    text: '?',
                },

                type: '?',

                customer_contact_name:   '?',
                customer_contact_number: '?',
            }

            if (this.port) {
                let status = 'info'

                switch (this.port.Status) {
                    case 'Invalid':
                    case 'Rejected':
                    case 'Cancelled':
                    case 'Request Expired':
                    case 'Closed':
                    case 'Failed':
                    case 'Expired': {
                        status = 'danger'
                    } break

                    case 'Awaiting LSP Response':
                    case 'Awaiting GSP Approval':
                    case 'Expiring':
                    case 'Expiry Pending':
                    case 'Withdrawal Pending':
                    case 'Awaiting SMS Acceptance':
                    case 'Awaiting APC Approval': {
                        status = 'warning'
                    } break

                    case 'In Progress':
                    case 'LSP Response Received Awaiting SMS Acceptance':
                    case 'SMS Response Received Awaiting LSP Response': {
                        status = 'acknowledged'
                    } break

                    case 'Approved':
                    case 'Withdrawn':
                    case 'Accepted':
                    case 'GC and LC Complete': {
                        status = 'success'
                    } break
                }

                data = {
                    uuid: this.port.UUID,

                    started_at: formatDateNano(this.port.RfsDateTimeStartNanos),

                    status: {
                        status: status,
                        text: this.port.Status,
                    },

                    type: this.port.ProductType
                        ? PRODUCT_TYPES[this.port.ProductType] || this.port.ProductType
                        : '?',

                    customer_contact_name: this.port.CustomerContactName,
                    customer_contact_number: this.port.CustomerContactNumber,
                }
            }

            return data
        },
    },

    watch: {
        uuid() {
            if (this.port_uuid) {
                this.getPortDetails()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-number-porting-number-port-details {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 24px;
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {}
</style>