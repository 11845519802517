import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    MSISDN: {
        name: 'MSISDN',
        required: true,
        format: /^\d{12,}$/,
    },

    LSP: {
        name: 'LSP',
        required: true,
        format: /^\d+$/,
    },

    AccountNumber: {
        name: 'Account number',
        required: true,
        format: /^\d+$/,
    },

    CustomerName: {
        name: 'Customer name',
        required: true,
        format: /^(?=.{1,32}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
    },

    OldSIMID: {
        name: 'Old SIMID',
        required: true,
        format: /^\d{12,}$/,
    },
})