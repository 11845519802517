<template>
    <div id="page-number-porting-number-port" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to Number Ports</router-link>

                    <h1 class="heading" v-if="is_edit_mode">Edit port</h1>
                    <h1 class="heading" v-else>Create port</h1>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board">
                        <div class="form-group" v-if="!is_edit_mode">
                            <div class="form-controls">
                                <app-dropdown-select
                                    v-model="port.ProductType"
                                    :options="product_types"

                                    key-value="value"
                                    key-title="title"

                                    label="Product type"
                                />
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="form-controls">
                                <!-- MSISDN -->
                                <app-input
                                    v-model="port.MSISDN"

                                    label="MSISDN"

                                    :required="true"

                                    :error="errors.fields.MSISDN"

                                    @change="errors.fields.MSISDN = null"
                                />
                            </div>

                            <div class="form-controls">
                                <!-- LSP -->
                                <app-input
                                    v-model="port.LSP"

                                    label="LSP"

                                    :required="true"

                                    :error="errors.fields.LSP"

                                    @change="errors.fields.LSP = null"
                                />
                            </div>
                        </div>

                        <div class="form-group" v-if="port.ProductType == 'postpaymobile'">
                            <div class="form-controls">
                                <!-- AccountNumber -->
                                <app-input
                                    v-model="port.AccountNumber"

                                    label="Account number"

                                    :required="true"

                                    :error="errors.fields.AccountNumber"

                                    @change="errors.fields.AccountNumber = null"
                                />
                            </div>

                            <div class="form-controls">
                                <!-- CustomerName -->
                                <app-input
                                    v-model="port.CustomerName"

                                    label="Customer name"

                                    :required="true"

                                    :error="errors.fields.CustomerName"

                                    @change="errors.fields.CustomerName = null"
                                />
                            </div>
                        </div>

                        <div class="form-group" v-else>
                            <div class="form-controls">
                                <!-- OldSIMID -->
                                <app-input
                                    v-model="port.OldSIMID"

                                    label="SIM Card ID"

                                    :required="true"

                                    :error="errors.fields.OldSIMID"

                                    @change="errors.fields.OldSIMID = null"
                                />
                            </div>
                        </div>

                        <app-error v-model="errors.show" :message="errors.message" />

                        <button class="btn btn-primary btn-submit" :disabled="!is_changed" @click="submit">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import NumberPortValidator from '@/validators/number-port-validator'
import errMessage from '@/helpers/errMessage'

import cloneDeep from 'lodash/cloneDeep'

const EMPTY_PORT = {
    ProductType: 'postpaymobile',

    MSISDN: null,
    LSP: null,

    AccountNumber: null,
    CustomerName: null,

    OldSIMID: null,
}

export default {
    props: {
        uuid: { type: String, default: null },

        customerUuid: { type: String, default: null },
        productUuid:  { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            port:          cloneDeep(EMPTY_PORT),
            original_port: cloneDeep(EMPTY_PORT),

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.is_edit_mode) {
                this.getPortDetails()
            }
        },

        getPortDetails() {
            this.loading = true

            const params = {
                UUID: this.port_uuid,
                SPID: this.current_spid,
            }

            /**
             * @todo
             */
            import('./test-port.json')
            // this.$store.dispatch('api_lmnpproto/GetServiceProviderPortByUUID', params)
                .then(response => {
                    console.log('response', JSON.parse(JSON.stringify(response)))

                    this.port =          cloneDeep(response)
                    this.original_port = cloneDeep(response)

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            let fields = {
                MSISDN: { rule: 'MSISDN', message: 'Please enter valid MSISDN' },
                LSP: { rule: 'LSP', message: 'Please enter valid LSP' },
            }

            if (this.port.ProductType == 'postpaymobile') {
                fields = {
                    ...fields,

                    AccountNumber: { rule: 'AccountNumber', message: 'Please enter valid account number' },
                    CustomerName: { rule: 'CustomerName', message: 'Please enter valid customer name' },
                }
            } else {
                fields = {
                    ...fields,

                    OldSIMID: { rule: 'OldSIMID', message: 'Please enter valid SIM Card ID' },
                }
            }

            for (const key in fields) {
                if (NumberPortValidator.isRuleExists(fields[key].rule)) {
                    if (NumberPortValidator.isInvalid(fields[key].rule, this.port[key], fields[key].message)) {
                        this.errors.fields[key] = NumberPortValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        submit() {
            if (this.validation()) {
                this.loading = true

                let action = 'api_postpay/PostpayProductPortRequest'

                let params = {
                    SPID: this.current_spid,

                    CustomerUUID: this.customerUuid,
                    UUID: this.productUuid,

                    MSISDN: this.port.MSISDN,
                    LSP: this.port.LSP,
                }

                if (this.port.ProductType == 'postpaymobile') {
                    // Postpay Product Port Request

                    params = {
                        ...params,

                        AccountNumber: this.port.AccountNumber,
                        CustomerName: this.port.CustomerName,
                    }
                } else {
                    // Prepay Product Port Request

                    action = 'api_prepay/PrepayProductPortRequest'

                    params = {
                        ...params,

                        OldSIMID: this.port.OldSIMID,
                    }
                }

                console.log('action', action)
                console.log('params', params)

                /**
                 * @todo
                 */
                import('./test-port.json')
                // this.$store.dispatch(action, params)
                    .then(response => {
                        console.log('response', JSON.parse(JSON.stringify(response)))

                        this.port =          cloneDeep(response)
                        this.original_port = cloneDeep(response)

                        this.errors.show = false
                        this.errors.message = ''

                        this.loading = false

                        if (this.on_sidebar) {
                            this.$emit('close-sidebar')
                            this.$emit('saved')
                        } else {
                            this.$router.push({
                                ...this.back_to,
                                replace: true,
                            })
                        }
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },

        reset() {
            console.warn('reset() {')

            this.errors = {
                show: false,
                message: '',

                fields: {},
            }

            if (!this.is_edit_mode) {
                this.original_port = cloneDeep(EMPTY_PORT)
            }

            this.port = cloneDeep(this.original_port)
        }
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'number-porting-number-ports'
                ? this.referrer
                : { name: 'number-porting-number-ports' }
        },

        port_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_edit_mode() {
            return Boolean(this.port_uuid)
        },

        is_changed() {
            /**
             * @todo
             */
            // let is_changed = false
            let is_changed = true

            return is_changed
        },

        product_types() {
            return [
                {
                    value: 'prepaymobile',
                    title: 'Prepay',
                },
                {
                    value: 'postpaymobile',
                    title: 'Postpay',
                },
            ]
        },
    },

    watch: {
        uuid() {
            if (this.is_edit_mode) {
                this.getPortDetails()
            } else {
                this.reset()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-number-porting-number-port {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .form-group {
            margin-bottom: 24px;

            .form-controls {
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        button {
            &.btn-submit {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    #page-number-porting-number-port {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .board {
            padding: 16px 8px;
        }

        button {
            &.btn-submit {
                max-width: 100%;
            }
        }
    }
}
</style>