<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <number-port v-if="sidebar.create.show || sidebar.edit.show"
                    :uuid="port_uuid"

                    ref="number-port"

                    @close-sidebar="closeSidebar"
                    @saved="getPorts"
                />

                <number-port-details v-else-if="sidebar.details.show"
                    :uuid="port_uuid"

                    ref="number-port-details"

                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-number-porting-number-ports">
            <app-loader v-if="loading" fixed-on="desktop" />

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="heading-box">
                            <h1 class="heading">Number Ports</h1>

                            <div class="dropdown-select-box" v-if="current_spid == main_spid">
                                <span>Filter by:</span>

                                <app-dropdown-select
                                    v-model="filter.service_provider"
                                    :options="service_providers"

                                    key-value="value"
                                    key-title="title"

                                    label="Service Provider"

                                    :predictive-search="true"

                                    @change="getPorts(1)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <app-error v-model="errors.show" :message="errors.message" />

                        <app-table
                            class="ports-table"

                            :sort-value="filter.sort"
                            :sort-options="sort"
                            @sort-change="onSortChange"

                            :cols="cols"
                            :rows="rows"

                            :clickable="true"
                            :selected-row="port_uuid"

                            @row-click="toggleSidebar($event, 'details')"
                            @row-click-mobile="toggleSidebar($event, 'details')"

                            @edit-port="toggleSidebar($event, 'edit')"
                        />

                        <div class="actions">
                            <app-pagination
                                v-if="pagination.total"

                                v-model="pagination.page"

                                :total="pagination.total"
                                :limit="pagination.limit"
                                :page-range="pagination.range"

                                @change="onPageChange"
                            />

                            <!--
                                @todo

                                <button class="btn btn-primary btn-create-port"
                                    @click="createPort"
                                >Create Port</button>
                            -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import numberPort from '@/views/number-porting/number-port'
import numberPortDetails from '@/views/number-porting/number-port-details'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appDropdownSelect from '@/components/app-dropdown-select'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

const PRODUCT_TYPES = {
    prepaymobile: 'Prepay',
    postpaymobile: 'Postpay',
}

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        numberPort,
        numberPortDetails,

        appLoader,
        appError,
        appTable,
        appPagination,
        appDropdownSelect,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'RfsDateTimeStartNanos',
                    order: 'desc', // asc, desc
                },

                service_provider: null,
            },

            sort: [
                { field: 'RfsDateTimeStartNanos', title: 'Started At' },
                { field: 'Status',                title: 'Status'     },
                { field: 'ProductType',           title: 'Type'       },
            ],

            ports: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            sidebar: {
                create: { show: false, ref: 'number-port', to: () => ({ name: 'number-porting-number-port-create' }) },
                edit:   { show: false, ref: 'number-port', to: row => ({ name: 'number-porting-number-port-edit', params: { uuid: row.uuid } }) },

                details: { show: false, ref: 'number-port-details', to: row => ({ name: 'number-porting-number-port-details', params: { uuid: row.uuid } }) },
            },

            port_uuid: null,

            is_mobile_mode: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getPorts()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getPorts()
        },

        onPageChange(page) {
            this.getPorts(page)
        },

        getPorts(page) {
            this.loading = true

            let params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            if (this.current_spid == this.main_spid) {
                if (this.filter.service_provider) {
                    params = {
                        ...params,

                        SPID: this.filter.service_provider,
                    }
                }
            } else {
                params = {
                    ...params,

                    SPID: this.current_spid,
                }
            }

            this.$store.dispatch('api_lmnpproto/GetServiceProviderPortsPaginated', params)
                .then(response => {
                    this.ports = response.ServiceProviderPorts

                    this.pagination.page  = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            query: this.$route.query,
                            replace: true,
                        })
                    }

                    this.errors.show = false
                    this.errors.message = ''

                    this.loading = false
                })
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)

                    this.ports = []

                    this.loading = false
                })
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.port_uuid = null

            this.$emit('close-sidebar')

            for (const key in this.sidebar) {
                if (this.sidebar[key].show && this.$refs[this.sidebar[key].ref] && typeof this.$refs[this.sidebar[key].ref].reset == 'function') {
                    this.$refs[this.sidebar[key].ref].reset()

                    break
                }
            }
        },

        toggleSidebar(row, type) {
            if (this.is_mobile_mode) {
                this.$router.push(this.sidebar[type].to(row))
            } else {
                for (const key in this.sidebar) {
                    this.sidebar[key].show = false
                }

                this.sidebar[type].show = true

                if (row.uuid) {
                    this.port_uuid = row.uuid
                } else {
                    this.port_uuid = null
                }

                this.openSidebar()
            }
        },

        createPort() {
            this.toggleSidebar({}, 'create')
        },
    },

    computed: {
        ...mapGetters([
            'spid',
            'current_spid',
            'available_service_providers',

            '$mobile_size',
        ]),

        main_spid() {
            return +this.spid
        },

        service_providers() {
            return this.available_service_providers.map(provider => ({
                value: provider.SPID,
                title: provider.Name,
            }))
        },

        cols() {
            return [
                { key: 'uuid', title: 'ID', highlight: true },

                { key: 'started_at', title: 'Started At', sort: { field: 'RfsDateTimeStartNanos' } },
                { key: 'status',     title: 'Status',     sort: { field: 'Status'                }, type: 'status' },
                { key: 'type',       title: 'Type',       sort: { field: 'ProductType'           } },

                { key: 'number_details', title: 'Number details' },

                /**
                 * @todo
                 * 
                 * { key: 'edit_port', action: 'edit-port', icon: 'edit', behavior: 'detached' },
                 */
            ]
        },

        rows() {
            const rows = []

            const danger_statuses = [
                'Invalid',
                'Rejected',
                'Cancelled',
                'Request Expired',
                'Closed',
                'Failed',
                'Expired',
            ]

            for (let i = 0, len = this.ports.length; i < len; i++) {
                const port = this.ports[i]

                let status = 'info'

                switch (port.Status) {
                    case 'Invalid':
                    case 'Rejected':
                    case 'Cancelled':
                    case 'Request Expired':
                    case 'Closed':
                    case 'Failed':
                    case 'Expired': {
                        status = 'danger'
                    } break

                    case 'Awaiting LSP Response':
                    case 'Awaiting GSP Approval':
                    case 'Expiring':
                    case 'Expiry Pending':
                    case 'Withdrawal Pending':
                    case 'Awaiting SMS Acceptance':
                    case 'Awaiting APC Approval': {
                        status = 'warning'
                    } break

                    case 'In Progress':
                    case 'LSP Response Received Awaiting SMS Acceptance':
                    case 'SMS Response Received Awaiting LSP Response': {
                        status = 'acknowledged'
                    } break

                    case 'Approved':
                    case 'Withdrawn':
                    case 'Accepted':
                    case 'GC and LC Complete': {
                        status = 'success'
                    } break
                }

                rows.push({
                    uuid: port.UUID,

                    started_at: formatDateNano(port.RfsDateTimeStartNanos),

                    status: {
                        status: status,
                        text: port.Status,
                    },

                    type: port.ProductType
                        ? PRODUCT_TYPES[port.ProductType] || port.ProductType
                        : '?',

                    number_details: [
                        port.CustomerContactName,
                        port.CustomerContactNumber,
                    ].join(', '),

                    edit_port: {
                        disabled: !port.ProductType || danger_statuses.indexOf(port.Status) == -1,
                    },
                })
            }

            return rows
        },

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.create.show: {
                    title = 'Create port'
                } break

                case this.sidebar.edit.show: {
                    title = 'Edit port'
                } break

                case this.sidebar.details.show: {
                    title = 'Porting details'
                } break
            }

            return title
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-number-porting-number-ports {
    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 48px;

        h1 {
            &.heading {
                margin: 0 16px 0 0;
            }
        }

        .dropdown-select-box {
            display: flex;
            align-items: center;

            width: 100%;
            max-width: 375px;

            & > span {
                flex-shrink: 0;

                margin-right: 16px;

                font-size: 18px;
                color: var(--color-table-secondary);
            }
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .ports-table {
        // @todo
        // @include table-cols-width((300px, 150px, 250px, 100px, 250px, 48px), true, 1);

        @include table-cols-width((300px, 150px, 250px, 100px, 250px), true);
    }

    & > .container {
        & > .row {
            & > .col-12 {
                & > .actions {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
    
                    .app-pagination,
                    .btn-create-port {
                        margin-top: 30px;
                    }
    
                    .app-pagination {
                        flex-grow: 1;
    
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-number-porting-number-ports {
        .ports-table {
            // @todo
            // @include table-cols-width((260px, 120px, 180px, 80px, 250px, 48px), true, 1);

            @include table-cols-width((260px, 120px, 180px, 80px, 250px), true);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        flex-direction: column;
    
                        .app-pagination {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-number-porting-number-ports {
        .heading-box {
            flex-direction: column;
            align-items: flex-start;

            margin-bottom: 16px;

            h1 {
                &.heading {
                    margin: 0 0 32px 0;
                }
            }

            .dropdown-select-box {
                & > span {
                    margin-right: 4px;

                    font-size: 16px;
                }
            }
        }

        .ports-table {
            // @todo
            // @include table-cols-width-mobile((90px, 160px, 24px), true);

            @include table-cols-width-mobile((90px, 184px), true);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        .app-pagination,
                        .btn-create-port {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}
</style>